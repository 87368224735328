#copyright-text {
	font-family: Avenir-Roman, sans-serif;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
    color: #ffffff;
    margin-bottom: 10px;
}

.contact-email, .contact-phone .contact-address {
	font-size: 16px !important;
	margin-right: 10px !important;
	display: inline-block;
}

/* .contact-email {
	margin-right: 20px !important;
} */
