.about-image {
    opacity: 50;
    width: 100%;
    height: 100%;
}

#company {
    padding: 80px 0;
    background: linear-gradient(180deg, #ffffff 0%, #f8f9fa 100%);
}

#company .head {
    font-family: Avenir;
    font-size: 48px;
    font-weight: 900;
    line-height: 1.2;
    text-align: center;
    color: #333f5b;
    margin-bottom: 15px;
}

#company .subhead {
    font-family: Avenir-Roman;
    font-size: 20px;
    text-align: center;
    color: #0c4a80;
    margin-bottom: 60px;
    font-weight: 600;
}

#company .content-wrapper {
    display: grid;
    grid-template-columns: 1.2fr 0.8fr;
    gap: 60px;
    align-items: center;
    padding: 0 40px;
}

#company .text-section {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

#company .body {
    font-family: Avenir-Roman;
    font-size: 18px;
    line-height: 1.6;
    color: #505a72;
    margin: 0;
}

#company .body.highlight {
    font-size: 24px;
    color: #333f5b;
    font-weight: 500;
    line-height: 1.4;
}

#company .stats-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-top: 20px;
    padding-top: 40px;
    border-top: 1px solid rgba(12, 74, 128, 0.1);
}

#company .stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

#company .stat-number {
    font-family: Avenir;
    font-size: 36px;
    font-weight: 900;
    color: #0c4a80;
    margin-bottom: 8px;
}

#company .stat-label {
    font-family: Avenir-Roman;
    font-size: 16px;
    color: #505a72;
}

#company .image-section {
    position: relative;
}

#company .about-image {
    width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 20px 40px rgba(12, 74, 128, 0.1);
    transition: transform 0.3s ease;
}

#company .about-image:hover {
    transform: translateY(-10px);
}

@media (max-width: 1024px) {
    #company {
        padding: 60px 0;
    }

    #company .head {
        font-size: 36px;
    }

    #company .content-wrapper {
        grid-template-columns: 1fr;
        gap: 40px;
    }

    #company .image-section {
        order: -1;
    }

    #company .about-image {
        max-width: 600px;
        margin: 0 auto;
        display: block;
    }
}

@media (max-width: 768px) {
    #company {
        padding: 40px 0;
    }

    #company .head {
        font-size: 32px;
        padding: 0 20px;
    }

    #company .subhead {
        font-size: 18px;
        margin-bottom: 40px;
    }

    #company .content-wrapper {
        padding: 0 20px;
    }

    #company .body.highlight {
        font-size: 20px;
    }

    #company .stats-grid {
        gap: 20px;
    }

    #company .stat-number {
        font-size: 28px;
    }
}