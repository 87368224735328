.head {
	font-family: Avenir;
	font-size: 25px;
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: center;
	color: #333f5b;
	margin-top: 0;
	margin-bottom: 40px
}

#mission-top .head {
	margin-bottom: 10px
}

.body {
	font-family: Avenir-Roman;
	font-size: 20px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: center;
	color: #505a72;
}

#mission-top .section {
	margin-top: 80px
}

#mural {
	text-align: center;
	margin-top: -5px !important;
	margin-bottom: 15px !important;
	padding-top: 0
}

#mural img {
	width: 100%
}

#team {

	overflow: hidden
}

#team>.section {
	margin-top: 100px;
	margin-bottom: 25px;
	padding-top: 30px
}

#team #members {
	text-align: center
}

#team #members.section {
	margin-top: 25px;
	padding-top: 0
}

#team #members .img {
	border-radius: 50%;
	display: inline-block;
	overflow: hidden;
	padding: 5px;
	border: 1px solid transparent;
	height: 125px;
	box-sizing: border-box;
	width: 125px
}

#team #members img {
	border-radius: 50%;
	position: relative;
	top: 2px;
	width: 130px
}

#team #members h3 {
	font-family: Avenir-Roman;
	font-size: 20px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: center;
	color: #505a72
}

#team #members h4 {
	font-family: Avenir-Roman;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: center;
	color: #8a91a1
}

#team #members .member:hover .img {
	border-color: #6553c0
}

#team #members .member:hover h3 {
	color: #6553c0
}

#team #members .member:hover h4 {
	color: #333f5b
}

#team #more {
	text-transform: uppercase
}

#team #more button {
	display: block;
	margin-top: 30px;
	margin-left: auto;
	margin-right: auto
}

#team #more .body {
	margin-bottom: 100px
}

#location {
	background-color: #6553c0;
	overflow: hidden
}

#location>.grid {
	padding-top: 0;
	padding-bottom: 0
}

#location .text {
	padding: 80px 60px 80px 70px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center
}

#location .text .body {
	text-align: left;
	color: white;
	font-family: Avenir-Roman;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.88;
	letter-spacing: normal;
	color: #ffffff
}

#location .text .head {
	text-align: left;
	color: white;
	font-family: Avenir;
	font-size: 40px;
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.25;
	letter-spacing: normal;
	color: #ffffff;
	margin-bottom: 20px
}

#location .img>div {
	height: calc(100% + 15px);
	margin-right: -15px;
	position: relative
}

@media screen and (min-width: 1200px) {
	#location .img>div {
		margin-right: calc(-50vw + 585px);
		padding-bottom: 0;
		margin-bottom: -15px;
		height: calc((50vw - 600px + 715px) / 1.4)
	}
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
	/* #location .img>div {
		background-image: url(../../assets/images/map.png);
		background-position: center;
		background-size: cover
	}
	#location .img>div img {
		display: none
	} */
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) and (min-width: 1200px) {
	#location .img>div {
		margin-right: calc(-50vw + 593px)
	}
}

#location .img>div img {
	position: absolute;
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover
}

#investors .section {
	margin-top: 100px;
	margin-bottom: 69px;
	padding-top: 0;
	padding-bottom: 0
}

#investors .head {
	margin-bottom: 20px;
	font-family: Avenir;
	font-size: 25px;
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: center;
	color: #333f5b
}

#investors .body {
	opacity: 0.9;
	font-family: Avenir-Roman;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.88;
	letter-spacing: normal;
	text-align: center;
	color: #6d768a
}