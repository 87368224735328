#jumbotron>.section {
	padding-top: 0;
	padding-bottom: 75px
}

#jumbotron button {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px
}

#jumbotron .head {
	font-family: Avenir;
	font-size: 25px;
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: center;
	color: #333f5b
}

#jumbotron .body {
	font-family: Avenir-Roman;
	font-size: 20px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: center;
	color: #505a72;
	margin-top: 5px
}

#culture {
	background-color: #f4f5f7
}

#culture>.section {
	padding-top: 65px
}

#culture .section .grid {
	padding-right: 20px;
	padding-left: 20px
}

#culture .head {
	margin-bottom: 25px;
	font-family: Avenir;
	font-size: 25px;
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
	line-height: 2;
	letter-spacing: normal;
	color: #333f5b
}

#culture .body {
	margin-top: 0;
	margin-bottom: 30px;
	text-align: left;
	font-family: Avenir-Roman;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.88;
	letter-spacing: normal;
	color: #505a72
}

#culture .value {
	font-size: 18px;
	line-height: 30px;
	padding-bottom: 20px
}

#culture .value img {
	width: 50px;
	height: 50px;
	vertical-align: middle;
	border-radius: 50%;
	margin-right: 5px;
	position: relative;
	top: -2px
}


#help {
	padding-top: 80px;
	background-color: #6553c0
}

#help .head {
	font-family: Avenir;
	font-size: 25px;
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff
}

#help .body {
	font-family: Avenir-Roman;
	font-size: 20px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff
}

#benefits {
	background-color: #6553c0;
	padding-bottom: 80px
}

#benefits .head {
	font-family: Avenir;
	font-size: 20px;
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.25;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff
}

#benefits .head:after {
	content: '';
	display: block;
	width: 100px;
	height: 1px;
	opacity: 0.7;
	background-color: white;
	margin: 30px auto
}

#benefits .body {
	opacity: 0.7;
	font-family: Avenir-Roman;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.56;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
	max-width: 350px;
	margin: 0 auto
}

#benefits .benefit {
	padding: 30px
}

#jobs {
	padding-top: 30px;
	min-height: 400px;
}

#jobs .section {
	padding-bottom: 0;
}

#job-placeholder {
	height: 10vw;
}

#jobs .head {
	font-family: Avenir;
	font-size: 25px;
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: center;
	color: #333f5b;
	margin-bottom: 30px
}

#jobs .body {
	font-family: Avenir-Roman;
	font-size: 20px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: center;
	color: #505a72;
	margin-bottom: 0;
}

#job-list {
	display: table;
	width: 100%;
	color: white;
	padding-top: 35px;
}

#job-list .job {
	background-color: white;
	border-radius: 5px;
	border: solid 1px #dcdcde;
	margin-bottom: 10px;
	padding: 30px;
	display: block;
	text-decoration: none
}

#job-list .job:hover {
	border-color: #77AACA
}

#job-list .job:hover .location,
#job-list .job:hover .title {
	color: #333f5b
}

#job-list .row {
	display: table-row;
	font-family: Avenir-Roman;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.56;
	letter-spacing: normal;
	color: #6d768a
}

#job-list .cell {
	display: table-cell;
	white-space: nowrap
}

@media (max-width: 36em) {
	#job-list .location {
		display: none
	}
}

#job-list .title {
	width: 80%;
	font-family: Avenir-Roman;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.56;
	letter-spacing: normal;
	color: #929394
}

#job-list .location {
	width: 20%;
	font-family: Avenir-Roman;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.43;
	letter-spacing: normal;
	color: #929394
}

#job-list .apply {
	padding-left: 20px;
	width: 1%;
	font-family: Avenir-Roman;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.43;
	letter-spacing: normal;
	color: #77AACA
}

#job-list .apply a {
	text-decoration: none;
	color: #6d768a
}

#job-list .apply a:visited {
	color: #6d768a
}

#end {
	padding-top: 30px
}

#end .body {
	opacity: 0.9;
	font-family: Avenir-Roman;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.56;
	letter-spacing: normal;
	text-align: center;
	color: #6d768a
}

#end button {
	display: block;
	margin: 30px auto 110px auto
}