#contact {
    padding: 40px;
    background-color: #ffffff;
}

.contact-container {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    gap: 60px;
}

.left-section {
    flex: 1;
    padding-right: 40px;
}

.right-section {
    flex: 1.5;
}

h1 {
    font-family: Avenir, sans-serif;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

h2 {
    font-family: Avenir, sans-serif;
    font-size: 48px;
    font-weight: bold;
    color: #000;
    margin-bottom: 30px;
    line-height: 1.2;
}

.left-section p {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
    margin-bottom: 30px;
}

.left-section a {
    color: #007bff;
    text-decoration: none;
}

.social-links {
    display: flex;
    gap: 20px;
    margin-top: 30px;
}

.social-links a {
    color: #666;
    font-size: 24px;
    transition: color 0.3s;
}

.social-links a:hover {
    color: #007bff;
}

.form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.form-group {
    flex: 1;
}

label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #333;
}

.form-control {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

select.form-control {
    height: 48px;
}

textarea.form-control {
    min-height: 120px;
    resize: vertical;
}

.invalid-feedback {
    color: #dc3545;
    font-size: 14px;
    margin-top: 4px;
}


.submit-button {
    background-color: #0c4a80ba;
    color: white;
    border: none;
    padding: 12px 30px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: #77AACA;
}

.submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.form-group .grecaptcha-badge {
    margin: 20px 0;
}

@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        gap: 40px;
    }

    .left-section {
        padding-right: 0;
    }

    .form-row {
        flex-direction: column;
        gap: 15px;
    }

    h2 {
        font-size: 36px;
    }
}
