#why-choose-tysonworks {
  padding: 80px 40px;
  background-color: #ffffff;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(180deg, rgba(12, 74, 128, 0.02) 0%, rgba(12, 74, 128, 0.08) 100%);
}

#why-choose-tysonworks::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, rgba(12, 74, 128, 0) 0%, rgba(12, 74, 128, 0.1) 50%, rgba(12, 74, 128, 0) 100%);
}

#why-choose-tysonworks .head {
  font-family: Avenir;
  font-size: 42px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: #333f5b;
  margin-bottom: 15px;
}

#why-choose-tysonworks .subtitle {
  font-family: Avenir-Roman;
  font-size: 20px;
  text-align: center;
  color: #505a72;
  margin-bottom: 60px;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px 0;
}

.benefit-item {
  background: #ffffff;
  padding: 30px;
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(12, 74, 128, 0.1);
}

.benefit-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(12, 74, 128, 0.1);
}

.benefit-icon {
  font-size: 32px;
  margin-bottom: 20px;
}

.benefit-item h3 {
  font-family: Avenir;
  font-size: 22px;
  font-weight: 700;
  color: #333f5b;
  margin-bottom: 15px;
}

.benefit-item p {
  font-family: Avenir-Roman;
  font-size: 16px;
  line-height: 1.6;
  color: #505a72;
  margin: 0;
}

.cta-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 2rem 0;
  margin-top: 60px;
  padding: 40px;
  border-radius: 12px;

}

.cta-text {
  font-family: Avenir;
  font-size: 24px;
  font-weight: 600;
  color: #333f5b;
  margin-bottom: 20px;
}

.cta-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #0c4a80;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  font-family: Avenir;
  font-weight: 600;
  font-size: 18px;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background-color: #0a3d6b;
  transform: translateY(-2px);
  text-decoration: none;
  color: white;
}

@media (max-width: 1024px) {
  .benefits-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}

@media (max-width: 768px) {
  #why-choose-tysonworks {
    padding: 60px 20px;
  }

  #why-choose-tysonworks .head {
    font-size: 32px;
  }

  #why-choose-tysonworks .subtitle {
    font-size: 18px;
    margin-bottom: 40px;
  }

  .benefits-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .benefit-item {
    padding: 25px;
  }

  .cta-section {
    margin-top: 40px;
    padding: 30px 20px;
  }

  .cta-text {
    font-size: 20px;
  }
}
