#offering {
  background-color: white;
  padding: 40px 0 80px;
  margin-bottom: 0;
  overflow: hidden;
}

#offering > .grid {
  margin-bottom: 60px;
  position: relative;
  z-index: 1;
}

#offering .section {
  margin-bottom: 60px;
}

#offering .services-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(280px, 1fr));
  gap: 30px;
  padding: 0 20px;
}

#offering .headline {
  font-family: Avenir;
  font-size: 25px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #333f5b;
  margin-bottom: 20px;
  width: 100%;
}

#offering .feature {
  text-align: center;
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 12px;
  height: 100%;
  box-shadow: 0 4px 12px rgba(0,0,0,0.08);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
  overflow: hidden;
}

#offering .feature:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0,0,0,0.12);
}

#offering #monthly-services .feature {
  border: 1px solid #eef1f5;
}

#offering .feature .image {
  margin: 0 auto 20px auto;
  min-height: 100px;
  display: flex;
  align-items: center;
}

#offering .feature .image img {
  height: 100px;
  width: auto;
  object-fit: contain;
}

#offering .feature .headline {
  font-family: Avenir;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 15px;
  color: #333f5b;
}

#offering .feature .body {
  opacity: 0.85;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
}

#offering .feature a {
  margin-top: auto;
  padding: 12px 24px;
  color: #fff;
  background: #0c4a80ba;
  text-decoration: none;
  font-weight: 600;
  border-radius: 6px;
  transition: background 0.2s ease;
}

#offering .feature a:hover {
  background: #5d99bc;
  text-decoration: none;
}

#offering #monthly-services .feature .body {
  font-size: 15px;
  margin: 4px 0;
  position: relative;
  padding-left: 20px;
}

#offering #monthly-services .feature .body::before {
  /* content: "-"; */
  /* position: absolute; */
  /* left: 0; */
  /* color: #77AACA; */
  /* font-weight: bold; */
}

#services, #monthly-services {
  padding: 0 0 40px;
}

@media (max-width: 48em) {
  #offering {
      padding: 20px 15px 60px;
  }
  
  #offering .feature {
      margin-bottom: 20px;
  }

  #offering .services-grid {
      grid-template-columns: 1fr;
      gap: 20px;
      padding: 0;
  }
  
  #offering .section {
      margin-bottom: 40px;
  }
  
  #services, #monthly-services {
      padding-bottom: 20px;
  }
}