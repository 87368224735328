.main-content {
  min-height: 82.5vh;
}

#below-nav {
  -ms-flex-align: center;
  align-items: center;
  padding: 40px 20px 80px;
}

#below-nav .head {
  font-family: Avenir;
  font-size: 3em;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 30px;
  line-height: 1.09;
  color: #333f5b;
  margin-top: -0.5em;
}

#below-nav .body {
  font-family: Avenir-Roman;
  font-size: 19px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #505a72;
}

#below-nav .body .button-solid {
  display: inline-block;
}

#below-nav > .hero {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

#below-nav > .hero > div {
  flex: 1 1 300px;
  margin-bottom: 20px;
}

@media (max-width: 64em) {
  #below-nav .head {
    font-size: 2.5em;
  }
  
  #below-nav .body {
    font-size: 17px;
  }
  
  #below-nav > .hero {
    flex-direction: column;
  }
  
  #below-nav > .hero > div {
    flex: 1 1 100%;
  }
}

@media (max-width: 48em) {
  #below-nav .head {
    font-size: 2em;
  }
  
  #below-nav .body {
    font-size: 16px;
  }
}

#below-nav .form {
  vertical-align: top;
  margin-left: 50px;
}

#below-nav .form-text {
  font-family: Avenir-Roman;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #505a72;
  margin-top: 20px;
  margin-bottom: 10px;
}

#below-nav #mc_embed_signup {
  background: #fff;
  clear: left;
  font: 14px Helvetica, Arial, sans-serif;
  width: 100%;
}

#below-nav #mc_embed_signup form {
  text-align: left;
}

#below-nav #mc_embed_signup form input {
  margin-right: 10px;
  margin-bottom: 10px;
  clear: right;
}

#below-nav #mc_embed_signup form input[type="email"] {
  width: 280px;
  clear: right;
}

#below-nav #mc_embed_signup form input[type="submit"] {
  background-color: #00A3A3;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
}

#below-nav .img {
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 65px auto 65px auto;
}

@media (max-width: 36em) {
  #below-nav .img {
    background-size: cover;
  }
}

@media (max-width: 64em) {
    #jumbotron-img {
        display: none;
    }
}

#below-nav .img img {
  max-width: 500px;
  width: 100%;
}

#below-nav .img .mobile {
  display: none;
  width: 300px;
}

#main {
    background-color: #f4f5f7;
}

#main > .grid > .headline {
  font-family: Avenir;
  font-size: 25px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #333f5b;
}

#main .feature {
  text-align: center;
  padding: 30px 45px 70px 45px;
}

#main .feature .image {
  margin: 0 auto 15px auto;
}

#main .feature .image img {
  height: 150px;
}

#main .feature .headline {
  font-family: Avenir-Roman;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #505a72;
  margin-bottom: 20px;
}

#main .feature .body {
  opacity: 0.7;
  font-family: Avenir-Roman;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #505a72;
}

@media (max-width: 48em) {
  #main {
    padding-bottom: 50px;
  }
}

#help {
  padding-bottom: 68px;
}

#help .headline {
  font-family: Avenir;
  font-size: 25px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #333f5b;
  margin-top: 70px;
  margin-bottom: 10px;
}

#help .body {
  font-family: Avenir-Roman;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #505a72;
}

#help .contact {
  margin-top: 30px;
  text-align: center;
}

#news {
  /* background-color: #f4f5f7; */
}

#news .title {
  font-family: Avenir;
  font-size: 25px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #333f5b;
  margin-bottom: 50px;
}

#news .entry > .box {
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #dcdcde;
  margin: 0 auto 0 auto;
  padding: 20px 20px 30px 20px;
  width: 16em;
  display: block;
  text-decoration: none;
}

#news .entry > .box:hover {
  border-color: #00A3A3;
}

#news .entry img {
  margin-bottom: 30px;
  padding: 0;
  width: 4em;
}

#news .entry .headline {
  font-family: Avenir-Roman;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #505a72;
  margin-bottom: 30px;
}

#news .entry .body {
  font-family: Avenir-Roman;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #929394;
  /* margin-bottom: 30px; */
}

#news .entry .link {
  font-family: Avenir-Roman;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: normal;
  color: #00A3A3;
  text-decoration: none;
  position: relative;
  padding-bottom: 5px;
}

#news .inquiry {
  font-family: Avenir-Roman;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #929394;
  margin-top: 35px;
  margin-bottom: 75px;
}

#open-source {
  padding: 70px 0 55px 0;
  background: #f4f5f7;
}

#open-source .header {
  font-family: Avenir;
  font-size: 25px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #333f5b;
  margin-bottom: 30px;
}

#open-source .body {
  opacity: 0.9;
  font-family: Avenir-Roman;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #6d768a;
  margin-bottom: 25px;
}

#open-source .logos {
  text-align: center;
  display: block;
  margin: 0 auto;
}

#open-source .logos a {
  display: inline-block;
}

#open-source .logos a + a {
  margin-top: 25px;
}

#open-source .logos img {
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
  height: 100%;
}

@media (max-width: 48em) {
  #open-source .logos img {
    display: block;
    margin: 0 auto;
  }
}

#jumbotron {
  margin-top: 1em;
}

#jumbotron-img {
  margin-left: 60px
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky + #jumbotron {
  padding-top: 1em;
}

#header {
  color: #333f5b;
}

#partner-logos {
    padding-top: 5em;
}

#apn-logo {
    width: auto;
   height: 4em;
}

#gcp-logo {
    width: auto;
    height: 5em;
}

#newsletter-form {
    margin-top: 3em;
}

.margin-top-3em {
    margin-top: 3em;
}

.margin-bottom-3em {
    margin-bottom: 3em;
}

.padding-bottom-3em{
    padding-bottom: 3em;
}
