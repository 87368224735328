@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

#customers {
  padding: 70px 0 55px 0;
  overflow: hidden; /* Ensure that the overflowing part of the grid is not visible */
}

#customers .title {
    font-family: Avenir;
    font-size: 25px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #333f5b;
  }
  
  #customers .entry > .box {
    border-radius: 5px;
    /* border: solid 1px #dcdcde; */
    margin: 0 auto 0 auto;
    /* padding: 30px 30px 40px 30px; */
    display: block;
    text-decoration: none;
    height: 250px;
  }

  .customer-image {
    width: 80px;
    height: 64px;
    display: flex;
  }
  
  #customers .headline {
    font-family: Avenir-Roman;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #505a72;
  }
  
  #customers .entry .body {
    font-family: Avenir-Roman;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #929394;
    margin-bottom: 30px;
  }
  
  #customers .entry .link {
    font-family: Avenir-Roman;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: #6553c0;
    text-decoration: none;
    position: relative;
    padding-bottom: 5px;
  }
  
  #customers .entry .link::after {
    content: "";
    background-color: #705dc7;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 2px;
  }

  #customers .header {
    font-family: Avenir;
    font-size: 25px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #333f5b;
    margin-bottom: 30px;
  }
  
  #customers .body {
    opacity: 0.9;
    font-family: Avenir-Roman;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #6d768a;
  }

  .customer-slider {
    display: flex;
    overflow: hidden;
    width: 100%;
}

.customer {
    flex: 0 0 16.6667%; /* Assuming you want 6 items visible at a time, adjust as needed */
    opacity: 0;
    transition: opacity 0.5s ease;
}

.customer.visible {
    opacity: 1;
}

.customer-image {
  max-width: 100%;
  height: auto;
  display: block;
  /* margin: 0 auto; */
}

.slider-holder {
  padding-left: 4%;
}