#apn-badge {
    height: 11em;
    width: 9em;
    margin-left: 1em;
    margin-bottom: 0.5em;
}

.service-list { 
    font-size: 1.05em;
}

@media only screen and (max-width: 768px) {
    #splash-section {
        margin-top: -1em;
    }
}

#calendly-section {
    margin-top: -1.8em;
}
#services-section {
    margin-top: -1.8em;
    margin-right: 0.2em;
}