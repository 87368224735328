@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

#news {
    margin-top: 3em;
    margin-bottom: 3em;
}

#news .entry {
    display: flex;
    flex-direction: column;
  }

  
#news .news-image {
    width: 100%;
    /* Set a specific height or keep it responsive */
  }
  
#news .headline {
    font-size: 1.2em;
    height: 3em; /* Fixed height for two lines of text */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines you want to display */
    -webkit-box-orient: vertical;
  }
  
  #news .body {
    flex: 1; /* Allows the description to fill the available space */
    font-size: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6; /* Adjust the number of lines as needed */
    -webkit-box-orient: vertical;
  }
  
  #news .link {
    margin-top: auto; /* Push the read more link to the bottom */
  }
  
  /* Ensure the .box height is equal by defining a specific height or min-height */
  #news .box {
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
  }
  
  .slick-prev:before,
  .slick-next:before {
    color: #0c4a80ba!important;
  }